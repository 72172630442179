import ServerAPI from './ServerAPIs';

const { GET, GETV3 } = ServerAPI;

export const jobHasFeedback = (jobNumber, langCode) => {
  const url = `${process.env.REACT_APP_V3_HOST_URL}/customer-feedback/${jobNumber}?langCode=${langCode}`;
  return GETV3(url).then((response) => !!response?.rating);
};

export const getSurveyTags = () => {
  const url = `/customer-survey/surveyTags?surveyType=CSAT`;
  return GET(url);
};
