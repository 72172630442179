import {loadFromStorage} from "../../webStorage";

const translations = {
  translation: (function () {
    let langCode = loadFromStorage('CSAT', 'sessionstorage')?.langCode?.toLowerCase() || '';
    console.log("langCode", langCode);
    if (langCode.toLowerCase() === 'fr') {
      return require('./languageFr').translations;
    } else {
      return require('./languageEn').translations;
    }
  })(),
};
export default translations.translation;
