export const AppScreenTitle = {
  SignIn: 'Sign In',
  CreateAccount: 'Create An Account',
  VehicleNotFound: 'Vehicle Not Found',
  VerifyMobile: 'Verify Mobile',
  Home: 'Home',
  Profile: 'Profile',
  EditName: 'Edit Name',
  Vehicles: 'Vehicles',
  AddVehicle: 'Add Vehicle',
  JobHistory: 'Job History',
  About: 'About',
  ContactUs: 'Contact Us',
  GetHelp: 'How can we help!',
  DropOffLocation: 'Drop-Off Location',
  AddNotes: 'Add Notes',
  CoverageNotAvailable: 'Coverage Not Available',
};

export const MobileNumberScreenStrings = {
  InstructionMobile:
    'Please enter your mobile number\n to create this account.',
  MobileHint: 'Mobile Number',
  Verify: 'Verify',
  InstructionVerify: "We'll send a verification code \n to this number.",
};

export const VehicleDetailsScreenStrings = {
  FoundVehicle: 'We found your vehicle!',
  NameHint: 'Name',
  MobileHint: 'Mobile Number',
  Register: 'Register',
  Confirm: 'Confirm',
  TermCondition1: 'I have read and understand the',
  TermCondition2: 'Terms and Conditions',
  TermCondition3: 'and',
  TermCondition4: 'Privacy Notice.',
  AddVehicle: 'Add Vehicle',
};

export const VerifyMobileScreenStrings = {
  Instruction: 'Enter the 6-digit code sent to you at',
  VeificationCodeHint: 'Verification Code',
  ResendCode: 'Resend Code',
  Submit: 'Submit',
};

export const VehicleNotFoundStrings = {
  VehicleNotFoundDetail:
    'We need your vehicle details to ensure we can provide you with assistance.\n\nIt seems we cannot find your vehicle details in our system. Please call us directly and we will assist to update your information.\n\nIf you need assistance immediately, please also use the CALL US button below and we will assist.',
  CallUs: 'Call Us',
  VehicleNotFoundString1:
    'We need your vehicle details to ensure we can provide you with assistance.',
  VehicleNotFoundString2:
    'It seems we cannot find your vehicle details in our system. Please call us directly and we will assist to update your information.',
  VehicleNotFoundString3:
    'If you need assistance immediately, please also use the CALL US button below and we will assist.',
};

export const AddVehicleStrings = {
  VehicleRego: 'Registration Number (Rego)',
  DontKnowRego: 'I don’t know my Rego.',
  FindVehicle: 'Find Vehicle',
  EnterRego: 'To get started, enter your Rego.',
  EnterVin: 'Enter your VIN to get started.',
  VINHint: 'Vehicle Identification Number (VIN)',
  DontKnowVin: "I don't know my VIN.",
  Title: 'Create an Account',
};

export const SignUPScreenStrings = {
  VehicleVin: 'Enter your VIN to get started.',
  VINHint: 'Vehicle Identification Number(VIN)',
  DontKnowVin: 'I don’t know my VIN.',
  FindVehicle: 'Find Vehicle',
  VehicleRego: 'Enter your Rego to get started.',
  RegoHint: 'Registration Number(Rego)',
  DontKnowRego: 'I don’t know my Rego.',
  Title: 'Create an Account',
};

export const SplashScreenStrings = {
  SignInBtnTxt: 'Sign In',
  CreatAccountBtnTxt: 'Create An Account',
  CallTxt: 'Emergency? Call Us Now',
};

export const LoginScreenStrings = {
  MobileNoInstruction:
    'Please enter the mobile number you \n used to create your account.',
  MobileNumberHint: 'Mobile Number',
  MobileMessageInstruction:
    "We'll send a verification code \n  to this number.",
  Verify: 'Verify',
};

export const AboutScreenStrings = {
  Instruction:
    'Roadside assistance service provided by AWP Australia Pty Ltd ABN 52097227177 trading as Allianz Global Assistance.',
  ContactUs: 'Contact Us',
  VisitUsOnline: 'Visit Us Online',
  ViewTermsConditions: 'View Terms and Conditions',
  ViewPrivacyNotice: 'View Privacy Collection Notice',
  VisitUSLink: 'https://www.allianz-assistance.com.au',
  VisitUSText: 'www.allianz-assistance.com.au',
  TermsAndConditions: 'https://audi.roadsideonline.com.au/terms-and-conditions',
  PrivacyNoteLink: 'http://www.agaassistance.com.au/roadside/Privacynotice.pdf',
};

export const ProfileScreenStrings = {
  NameHint: 'Name',
  MobileHint: 'Mobile Number',
  Update: 'Update',
};

export const HomeMapStrings = {
  GetHelpHere: 'Get Help Here',
  UnableToFetchLocation:
    'We are having trouble getting your location. Please try again.',
  UnableToFetchLocationAddress: 'We are having trouble getting your address.',
  NoLocation: 'Please select location.',
  NoVehicle: 'Please select vehicle.',
  Search: 'Search',
  PlaceSearchFail: 'Please refine your search and retry.',
  DropoffInfoTextP1:
    'You are entitled to be towed to the nearest Audi dealer. We can also tow you to your preferred Audi dealer if it is within 20 km of the breakdown location.',
  DropoffInfoTextP2:
    'Please note that any additional towing costs outside this entitlement are your responsibility.',
  DontKnowDropoff: "I don't know the drop-off location",
  SetPinLocation: 'Set pin location',
  Cancel: 'Cancel',
};

export const Title = {
  AlertTitle: 'Alert',
  Oops: 'Oops!',
  Ok: 'Ok',
  Retry: 'Try Again',
  Cancel: 'Cancel',
  CancelSmall: 'cancel',
  Register: 'Register',
};

export const AddNotes = {
  ButtonTitle: 'Update',
};

export const CompleteJobString = {
  Complete: 'Complete',
  Close: 'Close',
};

export const Messages = {
  LocationError: '',
  APIFail: 'An error has occurred.',
  StorageDataLoadFail: 'Something went wrong!',
  MemberRegistrationFail:
    'An error occurred during registration. Please contact administration for assistance in registering.',
  MobileVerificationFail:
    'An error has occurred. Please check to see if you typed a correct verification code and try again.',
  ErrorTitle: 'Error',
  CancelButton: 'Cancel',
  TryAgainButton: 'Try Again',
  UserNotExist: 'User with this mobile number does not exists.',
  UserExist: 'User with this mobile number already exists.',
  CancelJob: 'Succesfully Cancelled Job',
  NoInternetTitle: 'No Internet Connection',
  NoInternetMessage: 'Make sure your device is connected to the internet.',
  OkTitle: 'OK',
  RootedOrJailBroken:
    'You’re using a version of the operating system that’s no longer supported. Please upgrade your device’s operating system to the newest version. Thanks!',
};

export const ContactUsScreenStrings = {
  AddressLine1: 'AWP Australia Pty Ltd',
  AddressLine2: 'ABN 52 097 227 177',
  AddressLine3: 'Trading as Allianz Global Assistance',
  AddressLine4: '74 High Street',
  AddressLine5: 'Toowong QLD 4066',
  AddressLine6: 'PO Box 162',
  AddressLine7: 'Toowong QLD 4066',
  PleaseContactString1: 'Please contact us on ',
  PleaseContactString2: ' for Roadside Assistance and general enquiries.',
  VisitUSText: 'www.allianz-assistance.com.au',
};

export const JobFlowMessages = {
  TryShortlyTitle: 'Please Try Again Shortly',
  CancelledTitle: 'Job Cancelled',
  ErrorTitle: 'Oops!',
  NoNearByAssistance:
    'There is no nearby assistance at the moment. However trucks are always in motion so check back shortly.',
  GOAMessage: 'Your job is cancelled because driver could not locate you.',
  JobCancelled: 'Your service request has been cancelled.',
  PaymentFailed: 'Payment failed for job.',
  CallDriver: 'Call Driver',
  ContactUs: 'Contact Us',
  Cancel: 'Cancel',
  Close: 'Close',
  Details: 'Details',
  Complete: 'Complete',
  OnSiteDescMessage: 'Please stay with your vehicle.',
  ContactingProviderTitleMessage: "We're looking for the best provider.",
  ContactingProviderDescMessage:
    "If we can't find one within 10 minutes,\n one of our staff will be in contact with you.",
};

export const GAScreenTitle = {
  HomeMap: 'Home',
  GetHelp: 'Get Help',
  SearchPlace: 'Search Place',
  DrawerMenu: 'Menu',
  Vehicles: 'Vehicles',
  ServiceRecord: 'Account History',
  Profile: 'Profile',
  EditProfile: 'Edit Profile',
  Logout: 'Logout',
  ConfirmDetail: 'Confirm Detail',
  AddNote: 'Add Note',
  AddVehicle: 'Add Vehicle',
  SignIn: 'Sign In',
  SignUp: 'Sign Up',
  Splash: 'Splash',
  CreateAccount: 'Create An Account',
  JobDetail: 'Job Detail',
  VerifyMobile: 'Verify Mobile',
  VehicleNotFound: 'Vehicle Not Found',
  About: 'About',
  JobComplete: 'Job Complete',
  FeedBack: 'Feedback',
  ThankYou: 'Thank You',
};

export const GAEventName = {
  HomeMap: 'Home',
  GetHelp: 'Get Help',
  GetHelpOutcome: 'Get Help Outcome',
  CallUs: 'Call Us',
  SelectedNearByDropOff: 'DropOff Selected',
  SearchOtherDropOffLocation: 'Search Other DropOff Location',
  CoverageNotFound: 'Coverage Not Found',
  DrawerMenu: 'Menu',
  SearchLocation: 'Search Location',
  ProfileUpdate: 'Profile Update',
  Profile: 'Profile',
  AboutUs: 'About',
  Logout: 'Logout',
  AddVehicle: 'Add Vehicle',
  Vehicles: 'Vehicles',
  ServiceRecord: 'Account History',
  AddVehicle: 'Add Vehicle',
  SignIn: 'Sign In',
  CreateAccount: 'Create An Account',
  VehicleDetail: 'Vehicle Detail',
  SignUpDone: 'Sign Up Done',
  FindVin: 'Find By VIN',
  FindRego: 'Find By REGO',
  VehicleNotFound: 'Vehicle Not Found',
  RequestHelp: 'Request Help',
  NewJobFailed: 'New Job-failed',
  ContactingProvider: 'Provider Contacting',
  CancelJob: 'Cancel Job',
  MarkVehicleDefault: 'Mark Vehicle Default',
  JobDetail: 'Job Detail',
  VerifyMobileCode: 'Verify Mobile Code',
  Submit: ' FeedBack Submitted',
  ServiceRating: 'Service Rating',
  Option1: 'Option 1 selected',
  Option2: 'Option 2 selected',
  Option3: 'Option 3 selected',
  Option4: 'Option 4 selected',
};

export const CoverageNotFoundString = {
  NoCoverageString:
    ' You do not currently have coverage for the assistance you are requesting.',
  CallUsForAssistance:
    ' If you need assistance immediately, please also use the CALL US button below.',
  CallUs: 'Call Us',
};

export const EditProfileString = {
  Name: 'Name',
  Update: 'Update',
  EditMobile: 'Edit Mobile Number',
  MobileNumber: 'Mobile Number',
  VerificationCodeSent: 'A verifcation code will be sent to this number.',
};

export const GetHelpString = {
  SendHelpCallUs: 'We can send help, but we will need you to first call us.',
  CallUs: 'Call Us',
  OtherLocation: 'Other Location',
  WeWillSendTow:
    'We will need to send a tow. Please confirm your vehicle’s location',
  ConfirmVehicleLocation: ' Please confirm your vehicle’s location',
  DropLocation: 'Where would you like your vehicle serviced?',
  KM: 'KM',
};

export const HomeString = {
  Profile: 'Profile',
  Vehicles: 'Vehicles',
  JobHistory: 'Job History',
  About: 'About',
  SignOut: 'Sign Out',
  Cancel: 'Cancel',
  PleaseSelcetLocation: 'please select location',
  ErrorWhileFetchLocation:
    'An error has occurred while fetching your location.',
  ErrorWhileSearchLocation: 'An error has occurred while searching location.',
  LocationError: 'Location Error',
  SessionExpireStrings: 'your session is expired, please sigin again.',
};

export const OtherLocationString = {
  Cancel: 'Cancel',
  YouAreTowedNearestString:
    ' You are entitled to be towed to the nearest Audi dealer. We can also tow you to your preferred Audi dealer if it is within 20 km of the breakdown location.',
  AdditionalCostString:
    'Please note that any additional towing costs outside this entitlement are your responsibility.',
};

export const RequestHelpString = {
  ConfirmVehicle: 'Please confirm your service details.',
  RequestHelp: 'Request Help',
  AddNotes: 'Add Notes',
  NoVehicle: 'Please select vehicle.',
};

export const StatusTrackerStrings = {
  CannotFindString:
    "If we can't find one within 10 minutes, one of our staff will be in contact with you.",
  ContactUS: 'Contact Us',
  CallDriver: 'Call Driver',
  Cancel: 'Cancel',
  CancleJob: 'Cancel Job?',
  CancleString:
    'You may be charged for this requested service since the driver is already on the way.',
  YesCancel: ' Yes, Cancel',
  No: 'No',
  PlaceholderPleaseExplain: 'Please explain',
};

export const ServiceRecordString = {
  NoRecords: 'No Records exist',
  JobHistory: 'Job History',
};
