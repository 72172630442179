import React from 'react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import { Switch, Route, HashRouter, Redirect } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './style.scss';
import './assets/Images/favicon.ico';
import Rating from './screens/Rating/Rating';
import ThankYou from './screens/ThankYou/ThankYou';
import registerServiceWorker from './registerServiceWorker';
import * as Sentry from '@sentry/react';
import ErrorFallback from './Sentry';
import * as buildInfo from './build-meta.json';

console.log('build info', buildInfo.default);

// APIConfigs.configureClient(appconfigs);
if (process.env.REACT_APP_BUILD == 'prod') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_BUILD,
    ignoreUrls: [
      'https://maps.googleapis.com/maps-api-v3/api/js/35/10a/poly.js',
    ],
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={ErrorFallback}>
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Rating} />
        <Route exact path="/CSAT" component={Rating} />
        <Route exact path="/thankyou" component={ThankYou} />
        <Route path="*">
          <Redirect to="/csat" />
        </Route>
      </Switch>
    </HashRouter>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
registerServiceWorker();
