export const loadFromStorage = (key) => {
  let item = sessionStorage.getItem(key);
  return item !== null ? JSON.parse(item) : {};
};

export const saveToStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeFromStorage = (key) => {
  sessionStorage.removeItem(key);
};
