export const translations = {
  WE_ARE_HAVING_TROUBLE_RIGHT_NOW:
      'We are having trouble right now. \n\n Please try again.',
  ERROR: 'Error',
  FEEDBACK: 'Feedback',
  HOW_WOULD_YOU_RATE: 'How would you rate this service?',
  GOOD_TO_HEAR: 'Good to hear.',
  TELL_US_WHAT_YOU_LIKED: 'Tell us what you liked.',
  SORRY_TO_HEAR: 'Sorry to hear.',
  TELL_US_WHAT_WE_CAN_IMPROVE: 'Tell us what we can improve.',
  SUBMIT: 'Submit',
  COMPLETED_JOB_DETAILS: 'Completed Job Details',
  CANCEL: 'Cancel',
  THANK_YOU: 'Thank You',
  API_FAILED: 'An error has occured',
  THANK_TEXT:
      'Thank you for taking the time to give us feedback. We use this information to learn and improve our product.',
  ADDITIONAL_QUESTIONS_OR_FEEDBACK:
      'If you have additional questions or feedback, please contact us at {{email}}',
};
