import React, { Component } from 'react';
import ReactGA from 'react-ga';
// Sonal Account tracking ID - UA-109673398-1

// Setting `dryRun` to `true` lets you test tracking without sending data to GA
//GoogleAnalyticsSettings.setDryRun(true);

let tracker = null;
function InitializeGA() {
  if (!tracker) {
    tracker = ReactGA.initialize(process.env.REACT_APP_TAG_MANAGER);
  }
}

export function trackScreen(screenName) {
  InitializeGA();
  var find = ' ';
  var re = new RegExp(find, 'g');
  screenName = screenName.replace(re, '-');
  console.log('trackScreen:screenName: ' + screenName);
  ReactGA.pageview(screenName);
}

export function trackEvent(scrname, evtName = '', partnerCode) {
  InitializeGA();
  let screenName = scrname;
  let eventName = evtName;
  if (screenName.toLowerCase() == eventName.toLowerCase()) {
    eventName = '';
  }
  var find = ' ';
  var title = '';
  var re = new RegExp(find, 'g');
  screenName = screenName.replace(re, '-');
  if (eventName.length > 0) {
    eventName = eventName.replace(re, '-');
    screenName = screenName + '#' + eventName;
  }
  if (partnerCode && process.env.REACT_APP_IS_TRACKER_APP === 'true') {
    title = scrname + '/' + partnerCode;
  }
  console.log('trackEvent:screenName: ' + screenName);
  ReactGA.pageview(screenName, '', title ? title : '');
}
