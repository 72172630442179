import React from 'react';
import { BACK_CHEVRON } from '../../utils/imageUtils';
import { ICON_CLOSE } from '../../utils/imageUtils';
import ic_close from '../../assets/Images/ic_close.svg';

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let backButton, rightButton;
    if (this.props.right) {
      backButton = (
        <a
          href="javascript:void(0)"
          className="back_arrow"
          onClick={this.props.backArrow}
        >
          <img src={BACK_CHEVRON} title="" alt="" />
        </a>
      );
      if (this.props.backButtonText) {
        rightButton = (
          <a
            href="javascript:void(0)"
            className="lnk"
            onClick={this.props.backButton}
          >
            <img src={ICON_CLOSE} title="" alt="" />
          </a>
        );
      }
    } else {
      if (this.props.backButtonText) {
        backButton = (
          <a
            href="javascript:void(0)"
            className="lnk"
            onClick={this.props.backButton}
          >
            {this.props.backButtonText}
          </a>
        );
      } else {
        backButton = (
          <a
            href="javascript:void(0)"
            className="back_arrow"
            onClick={this.props.backButton}
          >
            <img src={BACK_CHEVRON} title="" alt="" />
          </a>
        );
      }
    }
    return (
      <div>
        {!this.props.grange && (
          <header>
            <div className="container">
              <div className="left">{backButton}</div>
              <h2 className="title">{this.props.title}</h2>
              <div className="right">{rightButton}</div>
            </div>
          </header>
        )}
        {this.props.grange && (
          <div className="topbar">
            <div className="title">{this.props.title}</div>
            <div className="close">{rightButton}</div>
          </div>
        )}
      </div>
    );
  }
}

export default Header;
