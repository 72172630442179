import React from 'react';
import notification from '../../utils/notification';

class CheckAuthorization extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (this.props.securityTokenExpiration) {
      if (new Date().getTime() > this.props.securityTokenExpiration) {
        let data = {
          type: 'webCustVehicleDetermination',
          receiveDTO: {
            error: false,
            errorMessage: 'Token expired.',
            cancel: true,
          },
        };
        notification.postMessage(data);
      }
    }
  }
  render() {
    return <span />;
  }
}

export default CheckAuthorization;
