import React from 'react';
import Header from '../CommonComponent/Header';
import { trackScreen, trackEvent } from '../../utils/ga';
import {
  GAScreenTitle,
  Title,
  GAEventName,
} from '../../shared/Localization/LocalizedString';
import translate from '../../shared/Localization/translate';
import {loadFromStorage} from "../../webStorage";
class ThankYou extends React.Component {
  constructor(props) {
    super(props);
    const storageValue = loadFromStorage('CSAT','sessionstorage');
    this.state = {
      rating: 3, //this.props.location.state.rating,
      grange: false,
      activeClasses: ['', '', '', '', ''],
      isNative: false,
      partnerCode: storageValue.partnerCode,
    };
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    let activeClasses = this.state.activeClasses;
    activeClasses.forEach((item, index) => {
      if (index + 1 == this.state.rating) {
        if (this.state.partnerCode == 'volvo') {
          activeClasses[index] = 'volvo_selected';
        } else {
          activeClasses[index] = 'selected';
        }
      }
    });
    trackScreen(GAScreenTitle.ThankYou);
    if (
      (this.props.location.state && this.props.location.state.grange) ||
      process.env.REACT_APP_IS_TRACKER_APP === 'true'
    ) {
      this.setState({ grange: true });
      if (this.props.location.state && this.props.location.state.isNative) {
        this.setState({ isNative: this.props.location.state.isNative });
      }
    } else if (
      this.state.partnerCode === 'safeco' ||
      this.state.partnerCode === 'elephant' ||
      this.state.partnerCode  === 'grange' ||
      this.state.partnerCode  === 'amfam' ||
      this.state.partnerCode  === 'bmw' ||
      this.state.partnerCode === 'ford'
    ) {
      this.setState({ grange: true });
    }
    this.setState({
      activeClasses,
    });
  }

  onCancel() {
    if (
        this.state.partnerCode  == 'volvo'
    ) {
      window.location.href = 'app_volvo:close//';
    } else {
      if (this.state.grange) {
        if (
          this.props &&
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.isNative
        ) {
          window.location.href = 'app_grange:close//';
        } else {
          //window.location.href = "app_grange:close//";
          // if (process.env.REACT_APP_PARTNER === 'grange') {
          this.props.history.push('/policy-detail');
          // }
        }
      } else {
        window.postMessage(
          JSON.stringify({
            type: 'webCSAT',
            receiveDTO: {
              error: false,
              cancel: true,
            },
          }),
          '*'
        );
      }
    }
  }

  render() {
    return (
      <div className={this.state.grange ? 'feedback' : ''}>
        {process.env.REACT_APP_IS_TRACKER_APP === 'true' ? (
          <Header title="Feedback" right="true" grange={this.state.grange} />
        ) : (
          <Header
            backButtonText={translate.CANCEL}
            title={translate.FEEDBACK}
            right="true"
            grange={this.state.grange}
            backButton={this.onCancel}
          />
        )}
        <div className={this.state.grange ? 'mainContent' : 'main_content'}>
          <div className="feedback_pg">
            <div className="gap20 clear" />
            <div className="title">{translate.THANK_YOU}</div>
            <div className="smily_faces">
              <div
                className={
                  this.state.partnerCode == 'volvo'
                    ? 'smily_face_volvo ' + this.state.activeClasses[0]
                    : 'smily_face ' + this.state.activeClasses[0]
                }
              />
              <div
                className={
                  this.state.partnerCode == 'volvo'
                    ? 'smily_face_volvo ' + this.state.activeClasses[1]
                    : 'smily_face ' + this.state.activeClasses[1]
                }
              />
              <div
                className={
                  this.state.partnerCode == 'volvo'
                    ? 'smily_face_volvo ' + this.state.activeClasses[2]
                    : 'smily_face ' + this.state.activeClasses[2]
                }
              />
              <div
                className={
                  this.state.partnerCode == 'volvo'
                    ? 'smily_face_volvo ' + this.state.activeClasses[3]
                    : 'smily_face ' + this.state.activeClasses[3]
                }
              />
              <div
                className={
                  this.state.partnerCode == 'volvo'
                    ? 'smily_face_volvo ' + this.state.activeClasses[4]
                    : 'smily_face ' + this.state.activeClasses[4]
                }
              />
            </div>
            <div className="msg">{translate.THANK_TEXT}</div>
            {this.state.partnerCode ==='tredit' && (
              <div className="msg">
                {translate.ADDITIONAL_QUESTIONS_OR_FEEDBACK}{' '}
                <a href="mailto:support@tredit.com" title="">
                  support@tredit.com.
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ThankYou;
