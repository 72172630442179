/* global swal */
var notification = {
  notifyError: function (title, message) {
    if (title != '') {
      swal(title, message);
    } else if (title == '') {
      swal('', message);
    }
  },
  notifyErrorAndRedirect: function (title, message, redirectUrl) {
    if (title != '') {
      swal(title, message).then(() => {
        window.location = redirectUrl;
      });
    } else if (title == '') {
      swal('', message).then(() => {
        window.location = redirectUrl;
      });
    }
  },
  notifyErrorAndTryAgain: function (title, message, callback) {
    if (title != '') {
      swal({
        title: title,
        text: message,
        showCancelButton: true,
        confirmButtonText: 'Try Again',
      }).then(
        (result) => {
          if (result) {
            callback();
          }
        },
        (dismiss) => {
          return false;
        }
      );
    } else if (title == '') {
      swal({
        title: '',
        text: message,
        showCancelButton: true,
        confirmButtonText: 'Try Again',
      }).then(
        (result) => {
          if (result) {
            callback();
          }
        },
        (dismiss) => {
          return false;
        }
      );
    }
  },
};

export default notification;
