import APIConfigs from '../configs/AppConfigs';

import {
  BREAK_DOWN_TECH,
  BREAK_DOWN_TOW,
  KEY_TECH,
  FUEL_TECH,
} from './imageUtils';

/*
export function formatPhoneNumber(s,countryCode) {
  if(countryCode==61 || countryCode==44){
	  var s2 = ("" + s).replace(/\D/g, "");
	  var m = s2.match(/^(\d{4})(\d{3})(\d{3})$/);
	  return !m ? null : "" + m[1] + " " + m[2] + " " + m[3];
  }else if(countryCode==1){
	  var s2 = (""+s).replace(/\D/g, '');
	  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
	  return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
  } else {
	  var s2 = (""+s).replace(/\D/g, '');
	  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
	  return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
  }
} */

export const countryList = {
  AUS: '61',
  UK: '44',
  US: '1',
  IND: '91',
};

export const countryMobileLength = {
  AUS: '12',
  UK: '12',
  US: '14',
  IND: '12',
};

export const mobileNumberFormat = {
  AUS: {
    blocks: [4, 3, 3],
    delimiters: [' ', ' '],
    numericOnly: true,
  },
  UK: {
    blocks: [4, 3, 3],
    delimiters: [' ', ' '],
    numericOnly: true,
  },
  US: {
    blocks: [0, 3, 0, 3, 4],
    delimiters: ['(', ')', ' ', '-'],
    numericOnly: true,
  },
  IND: {
    blocks: [4, 3, 3],
    delimiters: [' ', ' '],
    numericOnly: true,
  },
};

export const serviceImageByOutCome = {
  3001: BREAK_DOWN_TECH,
  3002: BREAK_DOWN_TECH,
  3003: BREAK_DOWN_TOW,
  3004: KEY_TECH,
  3005: FUEL_TECH,
  3006: FUEL_TECH,
  3007: FUEL_TECH,
  3008: BREAK_DOWN_TECH,
  3009: BREAK_DOWN_TOW,
  2001: BREAK_DOWN_TECH,
  2002: BREAK_DOWN_TECH,
  2003: BREAK_DOWN_TOW,
  2004: KEY_TECH,
  2005: FUEL_TECH,
  2006: FUEL_TECH,
  2007: FUEL_TECH,
  2008: BREAK_DOWN_TECH,
  2009: BREAK_DOWN_TOW,
};

export function formatPhoneNumber(tel, countryCode) {
  if (countryCode == 1 || !countryCode) {
    if (tel) {
      var numbers = tel.replace(/\D/g, ''),
        char = { 0: '(', 3: ') ', 6: '-' };
      var phoneNumber = '';
      for (var i = 0; i < numbers.length; i++) {
        phoneNumber += (char[i] || '') + numbers[i];
      }
      return phoneNumber;
    }
  } else if (countryCode == 61) {
    if (tel) {
      var numbers = tel.replace(/\D/g, ''),
        char = { 4: ' ', 7: ' ' };
      var phoneNumber = '';
      for (var i = 0; i < numbers.length; i++) {
        phoneNumber += (char[i] || '') + numbers[i];
      }
      return phoneNumber;
    }
  } else if (countryCode == 44) {
    if (tel) {
      var numbers = tel.replace(/\D/g, ''),
        char = { 4: ' ', 7: ' ' };
      var phoneNumber = '';
      for (var i = 0; i < numbers.length; i++) {
        phoneNumber += (char[i] || '') + numbers[i];
      }
      return phoneNumber;
    }
  } else if (countryCode == 91) {
    if (tel) {
      var numbers = tel.replace(/\D/g, ''),
        char = { 4: ' ', 7: ' ' };
      var phoneNumber = '';
      for (var i = 0; i < numbers.length; i++) {
        phoneNumber += (char[i] || '') + numbers[i];
      }
      return phoneNumber;
    }
  } else {
    return tel;
  }
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function isValidNumber(event) {
  if (event.shiftKey || event.ctrlKey || event.altKey) {
    event.preventDefault();
    return false;
  } else {
    var charCode = event.which || event.keyCode;
    if (
      charCode != 8 &&
      charCode != 0 &&
      (charCode <= 47 || charCode >= 58) &&
      (charCode <= 95 || charCode >= 106)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}

export function onlyNumberAllowed(event) {
  if (event.shiftKey || event.ctrlKey || event.altKey) {
    event.preventDefault();
    return false;
  } else {
    var charCode = event.which || event.keyCode;
    if (
      charCode != 8 &&
      charCode != 0 &&
      (charCode <= 47 || charCode >= 58) &&
      (charCode <= 95 || charCode >= 106)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}

export function checkHostURL() {
  if (APIConfigs.HOST_URL == '') {
    window.location.href = '#/sign-in';
  }
}

export function checkAuthCode() {
  if (APIConfigs.AUTH_CODE == '') {
    window.location.href = '#/sign-in';
  }
}

export function upperCaseNumberRestriction(event) {
  if (event.shiftKey || event.ctrlKey || event.altKey) {
    event.preventDefault();
    return false;
  } else {
    var charCode = event.keyCode || event.which;
    if (
      charCode != 8 &&
      charCode != 0 &&
      (charCode <= 47 || charCode >= 58) &&
      (charCode <= 64 || charCode >= 91)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
export const getParameterByName= function(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}