import React from 'react';
import Header from '../CommonComponent/Header';
import { UrgentlyAPIs } from '../../shared/';
import Loader from 'react-loader';
import APIConfigs from '../../configs/AppConfigs';
import { JobAPI } from '../../shared/NetworkLayer/UrgentlyAPIs/APISignature';
import {
  getSurveyTags,
  jobHasFeedback,
} from '../../shared/NetworkLayer/UrgentlyAPIs/SurveyAPI';
import CheckAuthorization from '../CommonComponent/CheckAuthorization';
import notification from '../../shared/Utils/notification';
import * as checkRefresh from '../CommonComponent/RefreshToken';
import { trackScreen, trackEvent } from '../../utils/ga';
import {
  GAScreenTitle,
  GAEventName,
} from '../../shared/Localization/LocalizedString';
import { removeFromStorage, saveToStorage } from '../../webStorage';
import $ from 'jquery';
import translate from '../../shared/Localization/translate';
import { getParameterByName } from '../../utils/utils';
class Rating extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeClassesFaces: ['', '', '', '', ''],
      rating: 0,
      activeClassesRed: ['', '', '', '', ''],
      activeClassesGreen: ['', '', '', '', ''],
      jobDetail: {},
      tags: [],
      receivedData: {},
      loading: false,
      securityToken: null,
      savedCardInfo: [],
      paymentCardClass: 'fa-credit-card',
      grange: false,
      jobNumber: getParameterByName('jobNumber'),
      authToken: getParameterByName('accessToken'),
      refreshToken: getParameterByName('refreshToken'),
      partnerCode: getParameterByName('partnerCode'),
      langCode: getParameterByName('langCode'),
    };
    this.receivedData = null;
    this.submitBtn = null;
    this.selectedTags = [];
    this.submitData = this.submitData.bind(this);
    this.rateFacesClick = this.rateFacesClick.bind(this);
    this.redResponseClick = this.redResponseClick.bind(this);
    this.greenResponseClick = this.greenResponseClick.bind(this);
    this.getSurveyString = this.getSurveyString.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.notifyError = this.notifyError.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
    saveToStorage(
      'CSAT',
      {
        jobNumber: this.state.jobNumber,
        authToken: this.state.authToken,
        refreshToken: this.state.refreshToken,
        partnerCode: this.state.partnerCode,
        langCode: this.state.langCode,
      },
      'sessionstorage'
    );
    this.os = '';
    if (navigator.userAgent.match(/iPhone/)) {
      this.os = 'ios';
    }
  }

  notifyError(callback, params) {
    let title = translate.ERROR;
    let msg = translate.WE_ARE_HAVING_TROUBLE_RIGHT_NOW;
    notification.notifyErrorAndTryAgain(title, msg, callback, params);
  }

  submitData() {
    this.setState({ loading: true });
    var postParam = {
      jobNumber:
        this.state.receivedData && this.state.receivedData.sendDTO
          ? this.state.receivedData.sendDTO.jobNumber
          : this.state.jobNumber, //47479
      rating: this.state.rating,
      surveyType: 'CSAT',
      tags: this.selectedTags,
    };

    trackEvent(
      GAScreenTitle.FeedBack,
      GAEventName.Submit,
      this.state.partnerCode
    );
    /*
    if (this.state.grange && process.env.REACT_APP_IS_TRACKER_APP !== 'true') {
      let tokenRefreshRequired = false;
      // NOTE: IT's false until we confirm it with Miguel
      // let tokenRefreshRequired = checkRefresh.checkTokenExpiration(
      //   tokenExpiring
      // );
      if (
        process.env.REACT_APP_CONFIRMATION_LANDING_SCREEN ||
        (this.state.partnerCode === 'bmw.tr' &&
          this.props.vehicle.formType &&
          this.props.vehicle.formType === 'Reunite')
      ) {
        tokenRefreshRequired = false;
      }
      this.setState({ loading: true });
      if (tokenRefreshRequired) {
        checkRefresh.verifyToken(
          (response) => {
            if (response && response.accessToken) {
              APIConfigs.AUTH_CODE = response.accessToken;
              APIConfigs.REFRESH_TOKEN = response.refreshToken;
              let tokenExpiration =
                new Date().getTime() + response.expiresIn * 1000;
              this.setState({
                securityToken: tokenExpiration,
              });
              this.setState({ loading: false });
            }
            this.submitFeedback(postParam);
          },
          (error) => {
            this.setState({ loading: false });
          }
        );
      } else {
        this.submitFeedback(postParam);
      }
    } else {
    // */
      this.submitFeedback(postParam);
    //}
  }

  submitFeedback(postParam) {
    // POST API for tracker feedback submit
    /*
    if (process.env.REACT_APP_IS_TRACKER_APP === 'true') {
      const langCode = this.state.langCode;
      const url =
        APIConfigs.TRACKER.API_URL + '/customer-feedback?langcode=' + langCode;
      UrgentlyAPIs.POSTV3(url, postParam)
        .then((response) => {
          this.props.history.push({
            pathname: '/thankyou',
            state: {
              grange: this.state.grange,
              isNative: this.state.receivedData.isNative,
            },
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.notifyError(this.submitData);
        });
    } else {
    //*/
      UrgentlyAPIs.POST(JobAPI.submitSurvey.get, postParam)
        .then((response) => {
          this.props.history.push({
            pathname: '/thankyou',
            state: {
              grange: this.state.grange,
              isNative: this.state.receivedData?.isNative,
            },
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.notifyError(this.submitData);
        });
    //}
  }

  onCancel() {
    if (this.state.partnerCode == 'volvo') {
      window.location.href = 'app_volvo:close//';
    } else {
      if (this.state.grange) {
        if (this.state.partnerCode === 'grange') {
          window.location.href = 'app_grange:close//';
        } else {
          this.props.history.push('/policy-detail');
        }
      } else {
        window.postMessage(
          JSON.stringify({
            type: 'webCSAT',
            receiveDTO: {
              error: false,
              cancel: true,
            },
          }),
          '*'
        );
      }
    }
  }

  async getSurveyString() {
    this.setState({ loading: true });

    try {
      const tags = await getSurveyTags();
      this.setState({ tags });
    } catch (error) {
      notification.notifyError('', translate.API_FAILED);
    }

    this.setState({ loading: false });
  }

  getTrackerSurvey(langCode) {
    this.setState({ loading: true });
    const url =
      APIConfigs.TRACKER.API_URL +
      '/customer-feedback/surveyTags?surveyType=CSAT&langCode=' +
      langCode;
    UrgentlyAPIs.GETV3(url)
      .then((response) => {
        this.setState({ tags: response });
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        this.notifyError(this.getTrackerSurvey, langCode);
      });
  }

  rateFacesClick(index) {
    trackEvent(
      GAScreenTitle.FeedBack,
      GAEventName.ServiceRating,
      this.state.partnerCode
    );
    let activeClassesFaces = [];
    this.selectedTags = [];
    this.state.activeClassesFaces.forEach(function (value, idx) {
      if (idx == index - 1) {
        activeClassesFaces.push('selected');
      } else {
        activeClassesFaces.push('filled');
      }
    });
    this.setState({
      activeClassesFaces: activeClassesFaces,
      rating: index,
      activeClassesRed: ['', '', '', '', ''],
      activeClassesGreen: ['', '', '', '', ''],
    });
  }

  redResponseClick(index, tag) {
    if (index === 1) {
      trackEvent(
        GAScreenTitle.FeedBack,
        GAEventName.Option1,
        this.state.partnerCode
      );
    } else if (index === 2) {
      trackEvent(
        GAScreenTitle.FeedBack,
        GAEventName.Option2,
        this.state.partnerCode
      );
    } else if (index === 3) {
      trackEvent(
        GAScreenTitle.FeedBack,
        GAEventName.Option3,
        this.state.partnerCode
      );
    } else {
      trackEvent(
        GAScreenTitle.FeedBack,
        GAEventName.Option4,
        this.state.partnerCode
      );
    }

    let activeClassesRed = this.state.activeClassesRed;
    if (this.state.activeClassesRed[index] == 'active') {
      activeClassesRed[index] = '';
    } else {
      activeClassesRed[index] = 'active';
    }
    this.selectedTags.push(tag);
    this.setState({
      activeClassesRed,
    });
  }

  greenResponseClick(index, tag) {
    if (index === 1) {
      trackEvent(
        GAScreenTitle.FeedBack,
        GAEventName.Option1,
        this.state.partnerCode
      );
    } else if (index === 2) {
      trackEvent(
        GAScreenTitle.FeedBack,
        GAEventName.Option2,
        this.state.partnerCode
      );
    } else if (index === 3) {
      trackEvent(
        GAScreenTitle.FeedBack,
        GAEventName.Option3,
        this.state.partnerCode
      );
    } else {
      trackEvent(
        GAScreenTitle.FeedBack,
        GAEventName.Option4,
        this.state.partnerCode
      );
    }
    let activeClassesGreen = this.state.activeClassesGreen;
    if (this.state.activeClassesGreen[index] == 'active') {
      activeClassesGreen[index] = '';
    } else {
      activeClassesGreen[index] = 'active';
    }
    this.selectedTags.push(tag);
    this.setState({
      activeClassesGreen,
    });
  }

  componentDidMount() {
    /*
    if (process.env.REACT_APP_IS_TRACKER_APP === 'true') {
      trackScreen(GAScreenTitle.FeedBack);
      if (
        (this.props.location.state && this.props.location.state.data) ||
        localStorage.getItem('feedbackParams') !== null
      ) {
        let configData = this.props.location.state
          ? this.props.location.state.data
          : JSON.parse(localStorage.getItem('feedbackParams'));
        this.setState({ receivedData: configData });
        APIConfigs.TRACKER.API_URL = configData.sendDTO.apiHost;
        this.setState({
          grange: true,
          jobNumber: configData.sendDTO.jobNumber,
          langCode: configData.sendDTO.langCode,
        });
        const langCode = configData.sendDTO.langCode;
        this.getTrackerSurvey(langCode);
      }

      return;
    }
    */

    trackScreen(GAScreenTitle.FeedBack);

    APIConfigs.ACCESS_TOKEN = '575a01ba-4cd4-43f5-aa54-d5aade1d5ef8';
    APIConfigs.AUTH_CODE = this.state.authToken;
    APIConfigs.REFRESH_TOKEN = this.state.refreshToken;

    this.setState({
      grange: true,
    });

    this.checkFeedbackSubmitted();
  }
  async checkFeedbackSubmitted() {
    this.setState({ loading: true });

    try {
      const feedBackExists = await jobHasFeedback(
        this.state.jobNumber,
        this.state.langCode
      );
      if (feedBackExists) {
        this.props.history.push({
          pathname: '/thank',
          state: {
            grange: true,
            isNative: false,
          },
        });

        return;
      }

      await this.getSurveyString();
    } catch (error) {
      notification.notifyError('', translate.API_FAILED);
    }

    this.setState({ loading: false });
  }

  isVolvoApp = () => {
    if (this.state.partnerCode == 'volvo') {
      return true;
    } else {
      return false;
    }
  };

  render() {
    var greenTags, redTags;
    if (this.state.tags.length > 0) {
      redTags = this.state.tags.map((record, index) => {
        return (
          <a
            className={'box ui-link ' + this.state.activeClassesRed[index + 1]}
            href="javascript:void(0)"
            onClick={() => this.redResponseClick(index + 1, record)}
            key={'redTags' + index}
          >
            <span className="fa fa-minus" />
            <span className="name">{record}</span>
          </a>
        );
      });
      greenTags = this.state.tags.map((record, index) => {
        return (
          <a
            className={
              'box ui-link ' + this.state.activeClassesGreen[index + 1]
            }
            href="javascript:void(0)"
            onClick={() => this.greenResponseClick(index + 1, record)}
            key={'greenTags' + index}
          >
            <span className="fa fa-plus" />
            <span className="name">{record}</span>
          </a>
        );
      });
    }

    if (this.os == 'ios') {
      $('.csat').addClass('ios');
    }
    return (
      <div>
        <Loader loaded={!this.state.loading} color="#fff" />
        <CheckAuthorization
          securityTokenExpiration={this.state.securityToken}
        />
        <div className={this.state.grange ? 'feedback' : ''}>
          {/*
          {process.env.REACT_APP_IS_TRACKER_APP === 'true' ? (
            <Header
              title={translate.FEEDBACK}
              right="true"
              grange={this.state.grange}
            />
          ) : (
          */}
            <Header
              backButtonText={translate.CANCEL}
              title={translate.FEEDBACK}
              right="true"
              grange={this.state.grange}
              backButton={this.onCancel}
            />
          {/*
          )}
          */}
          <div className={this.state.grange ? 'mainContent' : 'main_content'}>
            <div className="gap20 clear" />
            <div className="rate_form">
              <div className="title">{translate.HOW_WOULD_YOU_RATE}</div>
              <div className="rate_faces">
                <div
                  className={
                    this.isVolvoApp()
                      ? 'rate_face face1_volvo ' +
                        this.state.activeClassesFaces[0]
                      : 'rate_face face1 ' + this.state.activeClassesFaces[0]
                  }
                  id="face1"
                  value="1"
                  onClick={() => this.rateFacesClick(1)}
                />
                <div
                  className={
                    this.isVolvoApp()
                      ? 'rate_face face2_volvo ' +
                        this.state.activeClassesFaces[1]
                      : 'rate_face face2 ' + this.state.activeClassesFaces[1]
                  }
                  id="face2"
                  value="2"
                  onClick={() => this.rateFacesClick(2)}
                />
                <div
                  className={
                    this.isVolvoApp()
                      ? 'rate_face face3_volvo ' +
                        this.state.activeClassesFaces[2]
                      : 'rate_face face3 ' + this.state.activeClassesFaces[2]
                  }
                  id="face3"
                  value="3"
                  onClick={() => this.rateFacesClick(3)}
                />
                <div
                  className={
                    this.isVolvoApp()
                      ? 'rate_face face4_volvo ' +
                        this.state.activeClassesFaces[3]
                      : 'rate_face face4 ' + this.state.activeClassesFaces[3]
                  }
                  id="face4"
                  value="4"
                  onClick={() => this.rateFacesClick(4)}
                />
                <div
                  className={
                    this.isVolvoApp()
                      ? 'rate_face face5_volvo ' +
                        this.state.activeClassesFaces[4]
                      : 'rate_face face5 ' + this.state.activeClassesFaces[4]
                  }
                  id="face5"
                  value="5"
                  onClick={() => this.rateFacesClick(5)}
                />
              </div>
              <div
                id="good_text"
                style={{ display: 'none' }}
                className={
                  this.state.rating > 3 && this.state.rating != 0
                    ? 'show'
                    : 'hide'
                }
              >
                <div className="title mb0">{translate.GOOD_TO_HEAR}</div>
                <div className="title">{translate.TELL_US_WHAT_YOU_LIKED}</div>
              </div>
              <div
                id="sorry_text"
                style={{ display: 'none' }}
                className={
                  this.state.rating < 4 && this.state.rating != 0
                    ? 'show'
                    : 'hide'
                }
              >
                <div className="title mb0">{translate.SORRY_TO_HEAR}.</div>
                <div className="title">
                  {translate.TELL_US_WHAT_WE_CAN_IMPROVE}
                </div>
              </div>
              <div className="gap20 clear" />
              {this.state.rating < 4 &&
                this.state.tags.length > 0 &&
                this.state.rating != 0 && (
                  <div className="red_items_box">{redTags}</div>
                )}
              {this.state.rating > 3 && (
                <div className="green_items_box">{greenTags}</div>
              )}
            </div>
            {this.state.rating > 0 && (
              <div
                className="bottom_fix "
                id="btnNext"
                ref={(el) => (this.submitBtn = el)}
              >
                <div className="gap10 clear" />
                <a
                  href="javascript:void(0)"
                  onClick={this.submitData}
                  className="btn black ui-link"
                >
                  {translate.SUBMIT}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Rating;
