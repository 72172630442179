export default {
  ACCESS_TOKEN: '900b8351-ca41-48c7-ab89-bc2bc1722995',
  API_VERSION: process.env.REACT_APP_API_VERSION,
  INDUSTRY_TYPE: process.env.REACT_APP_INDUSTRY_TYPE,
  APP: process.env.REACT_APP_APP_TYPE,
  HOST_URL: process.env.REACT_APP_HOST_URL,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
  AUTH_CODE: '',
  REFRESH_TOKEN: '',
  ENVIROMENTUL:
    process.env.REACT_APP_BUILD == 'dev' || process.env.REACT_APP_BUILD == 'qa'
      ? 'dev'
      : 'prod',
  TRACKER: {
    ENV_ID: process.env.REACT_APP_BUILD === 'dev' ? '2' : '1',
    CLIENT: process.env.REACT_APP_TRACKER_CLIENT,
    INDUSTRY: process.env.REACT_APP_INDUSTRY_TYPE,
    APP: 'UrgentlyHTML5',
    API_VERSION: process.env.REACT_APP_API_VERSION,
    API_KEY: '029ccd43-a401-4b9b-b99f-b919f29a32ee',
    API_URL: '',
  },
};
