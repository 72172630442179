export const translations = {
  WE_ARE_HAVING_TROUBLE_RIGHT_NOW:
    'Nous avons de la difficulté techniques pour le moment. \n\n Veuillez réessayer.',
  ERROR: 'Erreur',
  FEEDBACK: 'Commentaires',
  HOW_WOULD_YOU_RATE: 'Veuillez évaluer votre expérience',
  GOOD_TO_HEAR: "Nous sommes heureux d'entendre ça",
  TELL_US_WHAT_YOU_LIKED: 'Dites-nous ce que vous avez apprecié',
  SORRY_TO_HEAR: "Nous sommes désolés d'apprendre cela",
  TELL_US_WHAT_WE_CAN_IMPROVE: 'Dites-nous ce que nous pouvons améliorer',
  SUBMIT: 'Soumettre',
  COMPLETED_JOB_DETAILS: 'Details de la commande de reparation',
  CANCEL: 'Annuler',
  THANK_YOU: 'Merci',
  API_FAILED: 'Une erreur est survenue',
  THANK_TEXT: `Je vous remercie de prendre le temps de nous donner vos commentaires. Nous utilisons cette information pour apprendre et améliorer nos produits.`,
  ADDITIONAL_QUESTIONS_OR_FEEDBACK:
    "Si vous avez d'autres questions ou des commentaires, contactez-nous à {{email}}",
};
